.slideInLeft {
  animation: slideInLeft 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
}

.slideOutLeft {
  animation: slideOutLeft 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000) both;
}

@keyframes slideOutLeft {
  0% {
      transform: translateX(0) translateZ(0);
      opacity: 1;
  }

  100% {
      transform: translateX(-1000px) translateZ(0);
      opacity: 0;
  }
}

@keyframes slideInLeft {
  0% {
      transform: translateX(-1000px) translateZ(0);
      opacity: 0;
  }

  100% {
      transform: translateX(0) translateZ(0);
      opacity: 1;
  }
}

.ss-ss {}