@font-face {
  font-family: 'Gotham-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./Assets/Fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham-Book';
  font-style: normal;
  font-weight: normal;
  src: url('./Assets/Fonts/Gotham-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham-Light';
  font-style: normal;
  font-weight: normal;
  src: url('./Assets/Fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./Assets/Fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'KoHo-Bold';
  font-style: normal;
  font-weight: bold;
  src: url('./Assets/Fonts/KoHo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'KoHo-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('./Assets/Fonts/KoHo-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'KoHo-SemiBold';
  font-style: normal;
  font-weight: 700;
  src: url('./Assets/Fonts/KoHo-Bold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
